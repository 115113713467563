<i18n>
ru:
  bookTable: Забронировать столик
  chipsTitle: Рестораны
  closeButton: Закрыть
  details: Подробнее
  more: Подробнее
  reservationUnavailable: Бронирование недоступно
  restaurantNotFound: Такого ресторана нет
  title: Рестораны
  viewList: Списком
  viewMap: На карте
  captionEmail: 'E-mail:'
  captionPhone: 'Телефон:'
  captionWork: 'График работы:'
ua:
  bookTable: Забронювати столик
  chipsTitle: Ресторан
  closeButton: Закрити
  details: Детальніше
  more: Детальніше
  reservationUnavailable: Бронювання недоступне
  restaurantNotFound: Такого ресторану немає
  title: Ресторан
  viewList: У списку
  viewMap: На карті
  captionEmail: 'Електронна пошта:'
  captionPhone: 'Телефон:'
  captionWork: 'Графік роботи:'
us:
  bookTable: Book a table
  chipsTitle: Restaurants
  closeButton: Close
  details: Details
  more: Read more
  reservationUnavailable: Reservation unavailable
  restaurantNotFound: There is no such restaurant
  title: Restaurants
  viewList: List view
  viewMap: Map view
  captionEmail: 'Email:'
  captionPhone: 'Phone:'
  captionWork: 'Work schedule:'
</i18n>

<template>
  <section v-if="pageStore.Restaurants.state === 'success'">
    <div
      v-if="
        (isMainPage &&
          (appConfig.VueSettingsPreRun.MainPageRestaurantListViewEnable ||
            appConfig.VueSettingsPreRun.MainPageRestaurantMapViewEnable)) ||
        (!isMainPage &&
          (appConfig.VueSettingsPreRun.HallsShowAsList || appConfig.VueSettingsPreRun.HallsShowAsMap))
      "
      class="v-halls v-row"
    >
      <div
        class="v-col-12 v-halls--title v-title v-mb-xxl"
        v-html="translate('restaurantList.title')"
      />
      <div
        v-if="appConfig.VueSettingsPreRun.HallsShowSearch"
        class="v-col-12 v-col-md-6 v-col-lg-7 v-col-xl-6 v-col-xxl-5 v-mb-xl"
      >
        <common-typeahead-input
          show-clear-icon
          show-search-icon
          :input="userInputSearch"
          :label="translate('search.label')"
          :min-chars="3"
          :user-input-changed="userInputSearchChanged"
          :zero-results="translate('restaurantList.restaurantNotFound')"
          :zero-results-subtitle="translate('search.changeQuery')"
          data-test-id="menu-search"
        />
      </div>
      <div
        v-if="appConfig.VueSettingsPreRun.HallsShowAsList && appConfig.VueSettingsPreRun.HallsShowAsMap"
        class="v-col-12 v-col-md-6 v-col-lg-5 v-col-xl-4 v-col-xxl-3 v-mb-xl v-d-flex v-justify-content-end v-align-items-center"
        :class="[
          appConfig.VueSettingsPreRun.HallsShowSearch
            ? 'v-offset-xl-2 v-offset-xxl-4'
            : 'v-offset-md-6 v-offset-lg-7 v-offset-xl-8 v-offset-xxl-9'
        ]"
      >
        <arora-option-slider
          :label="translate('deliveryTimeSelect.noDelivery')"
          :options="[false, true]"
          :theme="isMainPage ? 'secondary' : null"
          v-model:selected="showMap"
        >
          <template #option="option: { value: boolean }">
            <div
              v-if="option.value"
              v-html="translate('restaurantList.viewMap')"
            />
            <div
              v-else
              v-html="translate('restaurantList.viewList')"
            />
          </template>
        </arora-option-slider>
      </div>
      <transition
        mode="out-in"
        name="slidedown"
      >
        <div
          v-if="showMap"
          class="v-col-12 v-mb-md"
        >
          <maps-halls :reservation-callback="scrollToForm" />
        </div>
        <div
          v-else
          class="v-col-12"
        >
          <arora-swiper-slider
            v-if="isMainPage && appConfig.VueSettingsPreRun.MainPageRestaurantListViewEnable"
            class-name="v-halls__home v-mb-md"
            show-part-of-next-slide
            :max-items="
              pageStore.RestaurantsSorted.every((rest) => !rest.Photos || rest.Photos.length === 0)
                ? 3
                : 2.1
            "
            :items="pageStore.RestaurantsSorted"
            pagination-type="none"
          >
            <template #item="item: Hall">
              <div class="v-d-flex">
                <span class="v-icon"> <icon-address-mark-light /></span>
                <div
                  class="v-title"
                  v-html="sanitize(item.Title)"
                />
              </div>

              <div class="v-halls-card--summary v-row">
                <div
                  v-if="item.Photos.length > 0"
                  class="v-col-md-8 v-col-12 v-mb-sm v-position-relative"
                >
                  <div
                    v-if="
                      appConfig.VueSettingsPreRun.HallsShowAddressMapOrganisation &&
                      !stringIsNullOrWhitespace(item.AddressMapOrganisation)
                    "
                    class="v-position-absolute v-yandex-rating"
                  >
                    <iframe
                      :src="`https://yandex.ru/sprav/widget/rating-badge/${item.AddressMapOrganisation}`"
                      height="50"
                      width="150"
                    />
                  </div>
                  <arora-swiper-slider
                    class-name="v-image-slider"
                    loop
                    :autoplay-delay="appConfig.VueSettingsPreRun.HallsAutoscrollDelaySec * 1000"
                    :items="makePhotos(item)"
                    :max-items="1"
                    :show-part-of-next-slide="false"
                    pagination-type="none"
                  >
                    <template #item="imageDetails: ImageInfoWithID">
                      <arora-nuxt-image
                        disable-lazy
                        :alt="item.Title"
                        :image="imageDetails.Image"
                      />
                    </template>
                  </arora-swiper-slider>
                </div>
                <div :class="[item.Photos.length > 0 ? 'v-col-md-4 v-col-12' : 'v-col-12']">
                  <page-restaurant-info
                    is-main-page
                    :hall="item"
                  />
                </div>
              </div>

              <div
                :id="`v-hall-card-${item.ID}`"
                class="v-halls-card--head"
              />

              <div class="v-halls-card--info" />
            </template>
          </arora-swiper-slider>
          <common-cards-flex-mesh
            v-else-if="!isMainPage"
            class-name="v-halls-card"
            :items="pageStore.RestaurantsSorted"
            :max-items="3"
          >
            <template #item="item: Hall">
              <div
                :id="`v-hall-card-${item.ID}`"
                class="v-halls-card--head"
              >
                <div
                  v-if="item.Photos.length > 0"
                  class="v-mb-sm v-position-relative"
                >
                  <div
                    v-if="
                      appConfig.VueSettingsPreRun.HallsShowAddressMapOrganisation &&
                      !stringIsNullOrWhitespace(item.AddressMapOrganisation)
                    "
                    class="v-position-absolute v-yandex-rating"
                  >
                    <iframe
                      :src="`https://yandex.ru/sprav/widget/rating-badge/${item.AddressMapOrganisation}`"
                      height="50"
                      width="150"
                    />
                  </div>
                  <arora-swiper-slider
                    loop
                    :autoplay-delay="appConfig.VueSettingsPreRun.HallsAutoscrollDelaySec * 1000"
                    :items="makePhotos(item)"
                    :max-items="1"
                    :show-part-of-next-slide="false"
                    pagination-type="none"
                  >
                    <template #item="imageDetails: ImageInfoWithID">
                      <arora-nuxt-image
                        disable-lazy
                        :alt="item.Title"
                        :image="imageDetails.Image"
                      />
                    </template>
                  </arora-swiper-slider>
                </div>

                <page-restaurant-address :hall="item" />
              </div>
              <div class="v-halls-card--hr">
                <hr />
              </div>
              <div class="v-halls-card--info">
                <page-restaurant-info :hall="item" />
                <page-restaurant-buttons
                  show-details
                  :hall="item"
                  :reservation-callback="scrollToForm"
                />
              </div>
            </template>
          </common-cards-flex-mesh>
        </div>
      </transition>
      <div
        v-if="isMainPage && !showMap && appConfig.VueSettingsPreRun.MainPageRestaurantListViewEnable"
        class="v-col-12 v-mb-xxl"
      >
        <lazy-page-table-reservation-button />
      </div>
      <div
        v-if="pageStore.RestaurantsShowReservationForm && !isMainPage"
        class="v-col-12"
      >
        <div class="v-w-100 v-table-reservation-form-wrapper">
          <div class="v-col-12 v-col-md-10 v-offset-md-1 v-col-xl-8 v-offset-xl-2">
            <page-table-reservation-form>
              <template #item>
                <div
                  v-if="
                    selectedTableReservation &&
                    appConfig.VueSettingsPreRun.HallsTableReservationShowMiniature
                  "
                  :key="selectedTableReservation.ID"
                >
                  <template v-if="selectedTableReservation.Photos.length > 0">
                    <div
                      v-if="
                        appConfig.VueSettingsPreRun.HallsShowAddressMapOrganisation &&
                        !stringIsNullOrWhitespace(selectedTableReservation.AddressMapOrganisation)
                      "
                      class="v-position-relative v-table-reservation-miniature v-mr-sm"
                    >
                      <div class="v-position-absolute v-yandex-rating v-yandex-rating--miniature">
                        <iframe
                          :src="`https://yandex.ru/sprav/widget/rating-badge/${selectedTableReservation.AddressMapOrganisation}`"
                          height="50"
                          width="150"
                        />
                      </div>
                      <arora-nuxt-image
                        :alt="clean(selectedTableReservation.Title)"
                        :image="selectedTableReservation.Photos[0]"
                      />
                    </div>
                    <arora-nuxt-image
                      v-else
                      :alt="clean(selectedTableReservation.Title)"
                      :image="selectedTableReservation.Photos[0]"
                    />
                  </template>
                  <div>
                    <page-restaurant-address
                      disable-link
                      :hall="selectedTableReservation"
                    />
                  </div>
                </div>
              </template>
            </page-table-reservation-form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { TypeaheadOptions } from '~types/common'
import type { Hall } from '~types/pageStore'
import type { ImageInfoWithID } from '~types/props'

import { computed, onMounted, ref } from 'vue'

import { type GUID, useCommon } from '@arora/common'

const { isMainPage } = defineProps<{
  isMainPage?: boolean
}>()

const appConfig = useAppConfig()
const pageStore = usePageStore()

const selectedTableReservation = computed<Hall | undefined>(() => {
  if (!pageStore.SelectedTableReservationId) return undefined

  return (pageStore.Restaurants.data ?? []).find(
    (restaurant) => restaurant.ID === pageStore.SelectedTableReservationId
  )
})

const showMap = ref<boolean>(
  isMainPage
    ? !appConfig.VueSettingsPreRun.MainPageRestaurantListViewEnable
    : !appConfig.VueSettingsPreRun.HallsShowAsList
)

const userInputSearch = ref<string>('')

const { stringIsNullOrWhitespace } = useCommon()
const { sanitize, translate, clean } = useI18nSanitized()
const { keyboardSearchCheck } = useKeyboardCheck()

onMounted(() => {
  pageStore.initRestaurants()
})

function scrollToRestaurant(id: GUID): void {
  if (showMap.value) {
    const element = document.getElementById(`restaurant-${id}`)

    if (element) element.click()
  } else {
    const element = document.getElementById(`v-hall-card-${id}`)

    if (element) element.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }
}

function scrollToForm(): void {
  const element = document.getElementById('v-table-reservation-form')

  if (element) element.scrollIntoView({ block: 'center', behavior: 'smooth' })
}

async function userInputSearchChanged(value: string): Promise<TypeaheadOptions<Hall>[]> {
  if (value.length < 3) return []

  return pageStore.RestaurantsSorted.filter(
    (restaurant) =>
      keyboardSearchCheck(restaurant.Title, clean(value)) ||
      keyboardSearchCheck(restaurant.Address, clean(value))
  ).map((restaurant) => {
    return {
      ID: restaurant.ID,
      title: restaurant.Title,
      subtitle: translate('restaurantList.title'),
      onClick: () => {
        scrollToRestaurant(restaurant.ID)
      },
      item: restaurant
    }
  })
}

function makePhotos(item: Hall): ImageInfoWithID[] {
  return item.Photos.map((img) => {
    return { ID: useId(), Image: img }
  })
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-halls {
  &--title {
    font-size: variables.$TextSizeH2;
    font-weight: 600;
  }

  .v-typeahead {
    margin-bottom: 0;

    input {
      box-shadow: none;
    }
  }

  .v-halls-card {
    padding: 20px;
    @include mixins.trans;

    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content 2.5rem auto;
    gap: 0;
    align-items: stretch;

    grid-template-areas:
      'head'
      'hr'
      'info';

    &--hr {
      grid-area: hr;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 2.5rem;

      hr {
        width: 100%;
        color: variables.$BodyTextColor;
        opacity: 0.25;
      }
    }

    &--head {
      overflow: hidden;
      grid-area: head;

      display: flex;
      flex-direction: column;

      .v-swiper {
        .swiper-wrapper {
          padding-bottom: 0;
          margin-bottom: 0;

          .swiper-slide {
            .v-img-fluid {
              border-radius: variables.$BorderRadius;
            }
          }
        }
      }
    }

    &--info {
      grid-area: info;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 100%;
    }

    &:hover {
      scale: 1.05;

      @include mixins.sm {
        scale: 1;
      }
    }
  }
  &__home {
    .swiper-slide {
      padding: 20px;
      border-radius: 12px;
      background: variables.$BodyElementsBackground;
      border: 2px solid variables.$BorderColor;
      height: auto;
      &.swiper-slide-active {
        border: 2px solid variables.$SecondaryBackgroundColor;
      }
      .v-icon {
        width: 24px;
        height: 24px;
        path {
          fill: variables.$SecondaryBackgroundColor;
        }
      }
      .v-title {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 16px;
      }
      .v-image-slider {
        padding: 0;
        border: 0;
        background: transparent;
        img {
          border-radius: 12px;
        }

        .swiper-slide-active {
          border: 0;
          padding: 0;
        }
      }
    }

    .v-hall-info {
      display: flex;
      flex-direction: column;
      .v-caption {
        margin-bottom: 4px;
        flex: 0 0 100%;
        max-width: 100%;
        color: variables.$BodyTextColor;
      }
      &__order {
        &--email {
          order: 3;
        }
        &--work {
          order: 1;
        }
        &--phone {
          order: 2;
        }
      }
    }
  }
}

.v-table-reservation-form-wrapper {
  padding: 2.25rem 0;
  background: variables.$BodyElementsBackground;
  border-radius: variables.$BorderRadius;
  box-shadow: variables.$CardShadow;

  @include mixins.md {
    padding: 2.25rem;
  }

  @include mixins.sm {
    padding: 2.25rem 1.5rem;
  }
}

.v-yandex-rating {
  z-index: 2;
  right: 12px;
  top: 12px;

  &--miniature {
    right: -28px;
    top: -6px;

    iframe {
      scale: 0.5;
      border-radius: variables.$BorderRadius;
    }
  }

  iframe {
    border: none;
    border-radius: max(4px, calc(variables.$BorderRadius - 12px));
  }
}

.v-table-reservation-miniature {
  min-width: 165px;
  max-width: 165px;
  float: left;

  .v-img-fluid {
    width: 165px;
    height: auto;
    border-radius: max(4px, calc(variables.$BorderRadius - 12px));
    overflow: hidden;
  }
}
</style>
