<template>
  <ClientOnly>
    <maps-yandex-maps-halls
      v-if="appConfig.VueSettingsPreRun.MapsProvider === 'yandex'"
      :reservation-callback="reservationCallback"
    />
    <span
      v-else
      class="v-error-color"
      v-html="translate('mapsAddress.error')"
    />
  </ClientOnly>
</template>

<script setup lang="ts">
defineProps<{
  reservationCallback: () => void
}>()

const { translate } = useI18nSanitized()
const appConfig = useAppConfig()
</script>
